.filters {
    margin: 0 10px 30px;
    overflow: hidden;

    @media #{$mq-medium-screen} {
        margin: 0;
    }

    @media #{$mq-large-screen} {
        margin: 0;
    }
}

.filters-section {
    padding-bottom: 10px;
    float: left;
    clear: both;

    @media #{$mq-medium-screen} {
        padding-bottom: 0;
        float: none;
    }
}

.filter-title {
    margin-bottom: 5px;
    font-family: $font-menu;
    font-size: $font-size-text;
    font-weight: 400;
    text-transform: uppercase;
}

.filter-button {
    background-color: transparent;
    cursor: pointer;

    &:hover,
    &.active {
        h4 {
            color: $color-control-active;
        }
    }
}

.filter-items {
    margin-bottom: 25px;
    padding: 5px 0;

    li {
        margin-right: 2px;
        margin-bottom: 2px;
        font-size: 18px;
        text-transform: uppercase;

        @media #{$mq-medium-screen} {
            margin-right: 25px;
        }
    }
}

.filter-item {
    margin-bottom: 10px;
    float: left;
    font-size: $font-size-small;
    text-align: left;
    text-transform: uppercase;
}

.category-item {
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 2px;
}

.filter-item,
.category-item {

    @media #{$mq-medium-screen} {
        float: none;
    }

    .btn {
        display: block;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding-bottom: 4px;
    }

    .label {
        display: inline;
        padding: 4px 6px;

        transition: $transition-button-text-background;
        background-color: $color-control;
        color: $color-background;

        font-family: $font-text;
        font-size: 14px;

        font-weight: 500;
        line-height: 14px;
        text-transform: uppercase;

        -webkit-box-decoration-break: clone;
        -ms-box-decoration-break: clone;
        -o-box-decoration-break: clone;
        box-decoration-break: clone;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {

        .label {
            color: $color-elements-light;
        }
    }

    &.active {
        @media #{$mq-medium-screen} {
            margin-left: 4px;
        }

        .label {
            background-color: $color-control-active;
            color: $color-elements-light;

        }
    }
}

.filter-item-checkbox {
    .checkbox {
        display: none;
        line-height: 1;
    }
}

.filter-checkboxes {

    &.large {

        .filter-item {

            .label {
                padding: 2px 6px;
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}
