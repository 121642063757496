/**
 * Square grid, hopefully reusable someday
 */

$grid-menu-width: 510px;
$grid-menu-height: 380px;

$square-size-large: 250px;
$square-size-small: 120px;
$square-margin: 10px;

.grid-menu {
    .grid-menu-row {
        margin: 0 10px;

        &:nth-child(2) {
            margin-bottom: 10px;
        }
    }

    .grid-menu-item {
        display: block;
        position: relative;
        margin-bottom: 0;

        background-color: $color-elements-dark;
        color: $color-elements-light;

        a {
            transition: color 0.6s ease-out;
            &:hover {
                color: rgba(255, 255, 255, 0.7);
            }
        }

        &.grid-menu-item,
        &.grid-menu-item-small {
            margin-right: 0;
        }

        &.grid-menu-item-large {
            margin-bottom: 10px;
            padding-bottom: 100%;
        }

        &.grid-menu-item-small {
            width: 50%;
            margin: 0;
            padding-bottom: 50%;
            float: left;
        }

        .grid-menu-table-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .title-container {
            display: table;
            position: absolute;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;
            z-index: 2;

            .title {
                display: table-cell;
                padding: 0 $padding-default;
                text-align: center;
                vertical-align: middle;
            }
        }

        .code {
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 1;
        }
    }

    @media #{$mq-medium-screen} {
        .grid-menu-container {
            max-width: $grid-menu-width;
            margin: 0 auto;
        }

        .grid-menu-row {
            margin: 0 auto;
            margin-bottom: $square-margin;
        }

        .grid-menu-column + .grid-menu-column,
        .grid-menu-column + .grid-menu-column + .grid-menu-column {
            margin-left: $square-margin;
        }

        .grid-menu-column {
            width: $square-size-large;
            margin: 0 auto;
            float: left;

            &:only-child {
                float: none;
            }

            &.grid-menu-column-inverted {
                .grid-menu-item {
                    &.grid-menu-item-small {
                        &:nth-child(2) {
                            margin-right: 0;
                        }
                    }

                    &.grid-menu-item-large {
                        margin-top: $square-margin;
                    }
                }
            }

            &.grid-menu-column-normal {
                .grid-menu-item {
                    &.grid-menu-item-small {
                        &:nth-child(2) {
                            margin-right: $square-margin;
                        }
                        &:nth-child(3) {
                            margin-right: 0;
                        }
                    }
                    &.grid-menu-item-large {
                        margin-bottom: $square-margin;
                    }
                }
            }

            &.grid-menu-column-small {
                .grid-menu-item {
                    &.grid-menu-item-small {
                        &:nth-child(1n) {
                            margin-right: $square-margin;
                            margin-bottom: $square-margin;
                        }
                        &:nth-child(2n) {
                            margin-right: 0;
                            margin-bottom: $square-margin;
                        }
                        &:nth-child(6) {
                            margin-bottom: 0;
                        }
                        &:nth-child(7) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .grid-menu-item {
            margin-bottom: 0;
            float: left;

            &.grid-menu-item,
            &.grid-menu-item-small {
                margin-right: 0;
            }

            &.grid-menu-item-large {
                width: 100%;
                margin: 0;
            }

            &.grid-menu-item-small {
                width: 50%;
                margin: 0;
            }

            &.grid-menu-item-small {
                width: $square-size-small;
                height: $square-size-small;
                padding-bottom: 0;

                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    margin-right: $square-margin;
                }

                .code {
                    line-height: $square-size-small;
                }
            }

            &.grid-menu-item-large {
                width: $square-size-large;
                height: $square-size-large;
                padding-bottom: 0;

                &:first-child {
                    margin-right: $square-margin;
                }

                .code {
                    line-height: $square-size-large;
                }
            }

            .title-container {
                display: table;
            }
        }
    }
}
