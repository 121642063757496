.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    .menu-bottom {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
        padding: 10px;
        transition: transform 0.6s ease-out;
        text-align: center;
        visibility: hidden;
        z-index: 1;

        @media #{$mq-medium-screen} {
            display: block;
            visibility: visible;
        }

        &.invisible {
            display: none;
            visibility: hidden;
        }

        &.small {
            transform: translateY(10px);

            .menu-bottom-item {
                margin-right: -10px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    .bottom-label-section {
                        opacity: 1;
                    }
                    .bottom-icon-section {
                        opacity: 1;
                    }
                    .bottom-icon-passive {
                        opacity: 0;
                    }
                }
            }

            .bottom-icon-section,
            .bottom-icon-passive {
                @media #{$mq-medium-screen} {
                    transform: scale(0.7);
                }
            }

            .bottom-icon-passive {
                opacity: 1;
            }

            .inner-span {
                background-color: transparent;
            }
        }

        .menu-bottom-item {
            display: inline-block;
            position: relative;
            width: 70px;
            height: 70px;
            margin-right: 10px;
            margin-bottom: 10px;

            transition: background-image 0.6s ease-out, background-color 0.6s ease-out,
                transform 0.6s ease-out, margin-right 0.6s ease-out;
            background-color: transparent;
            background-repeat: repeat;

            cursor: pointer;
            vertical-align: middle;

            &:hover,
            &.focus {
                .bottom-label-section {
                    opacity: 1;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .bottom-icon-section {
            width: 100%;
            height: 100%;
            transition: transform 0.6s ease-out;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;

            &.icon-histoire {
                background-image: url('/img/trips/shapes/box-h-gray.svg');
            }

            &.icon-rencontres {
                background-image: url('/img/trips/shapes/box-r-gray.svg');
            }

            &.icon-reflexions {
                background-image: url('/img/trips/shapes/box-q-gray.svg');
            }

            &.icon-nte {
                background-image: url('/img/trips/shapes/box-n-gray.svg');
            }

            &.active {
                &.icon-histoire {
                    background-image: url('/img/trips/shapes/box-h-red.svg');
                }

                &.icon-rencontres {
                    background-image: url('/img/trips/shapes/box-r-red.svg');
                }

                &.icon-reflexions {
                    background-image: url('/img/trips/shapes/box-q-red.svg');
                }

                &.icon-nte {
                    background-image: url('/img/trips/shapes/box-n-red.svg');
                }
            }
        }

        .bottom-icon-passive {
            position: absolute;
            top: 0;
            width: 70px;
            height: 70px;
            transition: opacity 0.3s ease-out, transform 0.6s ease-out;

            background-image: url('/img/trips/shapes/box-gray.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            opacity: 0;

            &.active {
                background-image: url('/img/trips/shapes/box-red.svg');
            }
        }

        .bottom-label-section {
            display: table;
            position: absolute;
            top: -70px;
            left: 50%;
            width: 260px;
            height: 100%;
            margin-left: -130px;

            transition: opacity 0.3s ease-out;
            opacity: 0;

            .outer-span {
                display: table-cell;
                color: $color-red;

                font-family: $font-logo;
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                vertical-align: bottom;

                .inner-span {
                    padding: 0 5px;
                    background-color: $color-black;
                }
            }
        }
    }
}
