$transparent-light: rgb(0 0 0 / 20%);
$transparent-dark: rgb(0 0 0 / 40%);
$transparent-darker: rgb(0 0 0 / 80%);

.footer {
    width: 100%;
    clear: both;

    .left {
        max-width: 45%;
        float: left;

        .nte-logo {
            display: inline-block;
            width: 100px;
            height: 95px;
            background-image: url('/img/deco/logo.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 90%;
            transition: transform .15s ease-out;

            &:hover {
                transform: rotate(2deg) scale(1.05);
            }
        }

        .nte-links {
            display: inline-block;
            width: 235px;
            margin-top: 12px;
            vertical-align: bottom;

            @media #{$mq-large-screen} {
                margin-left: 8px;
            }

            & > div {
                height: 24px;
                margin-bottom: 4px;

                &:nth-child(2) a {
                    background-color: $transparent-light;
                }
            }

            a {
                display: inline;
                padding: 2px 5px;
                background-color: $transparent-dark;
                color: $color-text;
                font-family: $font-text;
                font-size: 18px;
                font-weight: bold;
                text-decoration: none;
                transition: color .2s ease-out, background-color .2s ease-out;

                &:hover {
                    background-color: $transparent-darker !important;
                    color: $color-hints;
                }
            }
        }
    }

    .right {
        display: none;
        max-width: 55%;
        height: 100px;
        float: right;
        visibility: hidden;

        @media #{$mq-large-screen} {
            display: block;
            visibility: visible;
        }

        .calq-logo {
            display: inline-block;
            height: 60px;
            margin-top: 20px;
            vertical-align: bottom;

            img {
                display: inline-block;
                max-width: 100%;
                margin: 0 auto;
            }
        }

        .calq-description {
            display: inline-block;
            height: 50px;
            padding-right: 8px;
            vertical-align: bottom;

            @media #{$mq-laptop-screen} {
                padding-right: 18px;
            }

            h4,
            p {
                display: block;
                color: $color-text;
                font-family: $font-text;
                font-size: 12px;
                text-decoration: none;
            }
        }
    }

    .bottom {
        padding: 5px 0 10px;
        border-top: 1px solid $color-brown;
        margin-top: 10px;

        .button, p {
            color: $color-text;
            font-family: $font-text;
            font-size: 12px;
            font-weight: bold;
            text-decoration: none;
        }

        .button {
            background-color: transparent;
            cursor: pointer;
        }

        .button-plain {
            color: $color-control-active;
            text-decoration: underline;
            transition: 0.15s color ease-out;

            &:hover {
                color: $color-brown;
            }
        }
    }
}
