/**
 * Containers
 */
.container {
    max-width: 960px;
    margin: 0 auto;

    @media #{$mq-medium-screen} {
        margin: 0;
    }

    @media #{$mq-large-screen} {
        max-width: 960px;
        margin: 0 auto;
    }

    @media #{$mq-very-large-screen} {
        max-width: 1186px;
    }

    @media #{$mq-huge-screen} {
        max-width: 1186px;
    }

}

/**
 * Cols
 */
.container-cols,
.cols {
    display: table;
    width: 100%;
    vertical-align: middle;

    .container-col,
    .col {
        display: table-cell;

        &.col-expand {
            width: 100%;
        }
    }

    &.cols-top {
        .col {
            vertical-align: top;

            &.col-top {
                vertical-align: top;
            }
        }
    }

    &.cols-bottom {
        .col {
            vertical-align: bottom;

            &.col-bottom {
                vertical-align: bottom;
            }
        }
    }
}

button, a {
    transition: $transition-text;
}

/**
 * Horizontal definition list
 */

dl {
    .horizontal {

        dt {
            display: inline-block;
            width: 320px;

            padding-right: 10px;

            float: left;
            clear: left;

            font-weight: bold;

            box-sizing: border-box;
        }

        dd {
            width: 100%;
            margin-bottom: 20px;
            float: left;
        }

        &::after {
            display: block;
            clear: both;
            content: '';
        }
    }

    @media #{$mq-medium-screen} {
        .horizontal {
            dd {
                width: calc(100% - 320px);
            }
        }
    }
}

.separator {
    width: 100%;
    height: 1px;
    //margin-bottom: $font-subtitle-padding-top;
    margin-bottom: 10px;
    background-color: $color-elements-dark;
}
