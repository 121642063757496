.video-player {
    position: relative;
    margin: 0 auto;

    .video-player-playback {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5px;
        background-color: $color-semitransparent;

        .video-player-playback-loading {
            background-color: $color-control;
        }

        .video-player-playback-current {
            background-color: $color-control-active;
        }
    }

    .video-player-controls {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .btn-playback {
            position: absolute;
            bottom: $padding-default;
            left: $padding-default;
        }

        .btn-full {
            position: absolute;
            right: $padding-default;
            bottom: $padding-default;
        }
    }

    .video-player-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        iframe {
            // width: 100%;
            // height: 100%;
        }
    }
}
