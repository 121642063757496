/**
 * Home Page
 */

$home-default-margin: 5px;
$home-large-margin: 20px;

// Top-level positioning for home page

.aside-container {
    &.aside-topright {
        margin: 0 10px;
    }

    @media #{$mq-medium-screen} {
        position: absolute;

        &.aside-topright {
            top: $home-default-margin;
            right: $home-default-margin;
        }

        &.aside-topleft {
            top: $home-large-margin;
            left: $home-large-margin;
        }
    }
}

.home,
.grid-menu-preview {
    .page-content {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 4;

        .page-content-container {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &.container {
        max-width: 100%;
    }

    .aside-topleft,
    .aside-topright {
        z-index: 3;
    }

    .logo {
        position: static;
    }

    .slideshow {
        z-index: 1;
    }

    .grid-menu-item {
        overflow: hidden;

        .title {
            font-family: $font-home;
            font-size: 30px;
            font-weight: 400;
            text-decoration: none;
            text-transform: uppercase;
        }

        .code {
            top: 50%;
            font-family: $font-logo;
            font-size: 170px;
            font-weight: 700;
            line-height: 1;
            text-decoration: none;
            //text-transform: uppercase;
        }
    }

    .grid-menu-item-small {
        background-color: $color-control;
        color: $color-elements-light;

        .title {
            font-size: $font-size-text;
        }

        .code {
            margin-top: -40px;
            color: $color-highlights;
            font-size: 80px;
        }
    }

    .grid-menu-item-large {
        background-color: $color-control;
        color: $color-elements-light;

        .code {
            margin-top: -85px;
            color: $color-highlights;
        }

        &:nth-child(2n) {
            background-color: $color-control-active;

            .code {
                color: $color-control;
            }
        }
    }

    .grid-menu-column-normal {
        .grid-menu-item-large {
            background-color: $color-control-active;
        }
    }

    .grid-menu-column-inverted {
        &:nth-child(1) {
            .grid-menu-item-small {
                &:first-child() {
                    background-color: $color-control-active;
                }
            }
        }

        &:nth-child(2) {
            .grid-menu-item-small {
                &:nth-child(2) {
                    background-color: $color-control-active;
                }
            }
        }
    }

    .grid-menu-column-small {
        .grid-menu-item-small {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(6) {
                background-color: $color-control-active;
            }
        }
    }

    /*
        Home Footer
    */

    @media #{$mq-medium-screen} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .container-table {
            display: table;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .container-middle {
            display: table-cell;
            width: 100%;
            vertical-align: middle;
        }

        .logo {
            .title,
            .subtitle {
                color: $color-elements-dark;
            }
        }

        .grid-menu-item {
            .code {
                top: 0;
                margin: 0;
            }
        }

        .footer-container {
            position: absolute;
            right: 5px;
            bottom: 5px;
            left: 20px;
            height: 30px;
        }

        .home-controls-container {
            float: left;
        }

        .home-controls-item {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: $home-default-margin;
            background-color: $color-control;
            background-image: url('/img/icons/arrow-right-triangle-inactive.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .home-caption {
            display: inline-block;
            padding: 3px 4px;
            float: right;

            background-color: $color-control;
            color: $color-elements-light;

            font-size: 11px;
            font-weight: bold;
            text-decoration: none;

            visibility: visible;

            span {
                font-weight: normal;
            }
        }
    }
}

.grid-menu-preview {
    position: relative;
    width: 100%;
    min-height: 380px;
    margin-top: 30px;
    margin-bottom: 10px;
    clear: both;
}

.home {
    .grid-menu-item {
        a {
            color: $color-elements-light;
        }
    }
}
