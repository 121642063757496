@mixin placeholderMixin ($color) {

    &::-webkit-input-placeholder {
        color: $color;
    }

    &:-moz-placeholder {
        color: $color;
    }

    &::-moz-placeholder {
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }

    &::placeholder {
        color: $color;
    }
}

@mixin clampMixin ($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}
