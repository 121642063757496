.audio-player {
    position: relative;
    padding-bottom: 30%;
    background-image: url('/img/backgrounds/pinboard.jpg');
    background-repeat: repeat;

    .audio-player-playback {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $color-elements-dark;

        .audio-player-playback-current {
            height: 5px;
            background-color: $color-control-active;
        }
    }
}

.audio-player-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100%;

    .btn {
        position: absolute;
        z-index: 2;
        top: $padding-large;
        left: $padding-large;
    }
}

.audio-caption {
    padding: $padding-default 0;

    p {
        line-height: 1;
    }
}

.audio-player-seek {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    canvas {
        width: 100%;
        height: 100%;
    }
}

.waveform {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    &.mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
