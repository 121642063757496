.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    line-height: 1.15;

    .logo {
        position: absolute;
        z-index: 11;
    }

    .menu {
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 20px;
        z-index: 1;
    }

    .menu-items {
        display: table-cell;
        max-height: 320px;
        vertical-align: middle;
    }

    .menu-item {
        display: block;
        position: relative;
        width: 70px;
        height: 70px;
        margin-bottom: 10px;

        transition: background-image 0.3s ease-out, background-color 0.3s ease-out;
        background-color: $color-elements-gray;
        background-repeat: repeat;

        &.active,
        &:hover {
            background-color: $color-red;
            background-image: none;
        }

        &:hover,
        &.focus {
            .label-section {
                opacity: 1;
            }
        }

        &.hidden {
            .label-section {
                display: none;
                visibility: hidden;
            }
        }
    }

    .icon-section {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 75%;

        &.icon-histoire {
            background-image: url('/img/trips/sections/section-1.svg');
        }

        &.icon-rencontres {
            background-image: url('/img/trips/sections/section-2.svg');
        }

        &.icon-reflexions {
            background-image: url('/img/trips/sections/section-3.svg');
        }

        &.icon-histoire_nte {
            background-image: url('/img/trips/sections/section-4.svg');
        }

        &.icon-section-5 {
            background-image: url('/img/trips/sections/section-5.svg');
        }
    }

    .label-section {
        display: table;
        position: absolute;

        top: 0;
        left: 100%;
        width: 150px;
        height: 100%;

        transition: opacity 0.3s ease-out;
        opacity: 0;

        span {
            display: table-cell;
            padding: 0 10px;

            color: $color-red;
            font-family: $font-menu;
            font-size: $font-size-logo;

            vertical-align: middle;
        }
    }
}
