.header {
    line-height: 1.15;
    z-index: 5;

    h1 {
        font-family: $font-title;
        font-size: $font-size-title;
    }

    .container-col {
        position: relative;
        vertical-align: middle;
    }

    .col-logo {
        height: 80px;
        z-index: 11;
    }

    .col-nav {
        vertical-align: top;
        z-index: 10;

        & > .container-cols {
            display: block;
        }
    }

    .col-search {
        display: block;
        position: absolute;
        top: 120px;
        right: 10px;
        font-size: 0;
        visibility: visible;
    }

    .menu {
        margin-top: 10px;
        background-image: url('/img/deco/lines.svg');
        background-repeat: no-repeat;
        background-position: top right;
        text-align: right;

        li {
            display: block;
            margin-right: 10px;
        }

        a {
            display: block;
            transition: color 0.3s ease-out;
            color: $color-control;
            font-family: $font-menu;
            font-size: 22px;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: $color-control-active;
            }
        }

        @media #{$mq-medium-screen} {
            margin-right: 10px;
            text-align: left;

            li {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .menu-toggle {
        display: block;
        width: 18px;
        height: 30px;
        padding-top: 55px;
        background-color: transparent;
        background-image: url('/img/icons/burger-close.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .search {
        margin-top: 20px;
    }

    &.closed {
        .menu-toggle {
            background-image: url('/img/icons/burger.svg');
        }
    }

    @media #{$mq-small-screen} {
        .menu {
            margin-top: 20px;

            li {
                display: inline-block;
            }
        }

        .col-search {
            display: block;
            position: absolute;
            top: 54px;
            right: 10px;
            visibility: visible;
        }
    }

    @media #{$mq-medium-screen} {
        position: fixed;
        top: 0;
        width: 100%;

        .col-search {
            display: block;
            position: absolute;
            top: -6px;
            right: 0;
            visibility: visible;
        }

        & > .container-cols {
            display: block;
        }

        .col-logo {
            display: block;
            position: absolute;
            top: 0;
        }

        .col-nav {
            display: block;
            margin-top: 20px;

            & > .container-cols {
                display: block;
            }

            .col-menu,
            .col-search {
                display: block;
            }
        }

        .search {
            margin-top: 0;
        }

        .menu {
            margin-top: 0;
            text-align: left;

            li {
                display: inline-block;

                &.active {
                    a {
                        color: $color-control-active;
                    }
                }

                &:hover {
                    a {
                        color: $color-control-active;
                    }
                }
            }

            a {
                transition: $transition-button-text-background;
                font-size: 18px;
            }
        }

        .menu-toggle {
            width: 30px;
            height: 16px;
            background-image: url('/img/icons/back.svg');
            cursor: pointer;

            // &:hover {
            // background-image: url('/img/icons/back-white.svg');
            // }
        }

        &.closed {
            .menu-toggle {
                background-image: url('/img/icons/forward.svg');

                // &:hover {
                // background-image: url('/img/icons/forward-white.svg');
                // }
            }

            .align-padding-left {
                padding-left: 0;
            }
        }

        &.collapsed {
            padding: 20px 0;
            border-bottom: 2px solid $color-brown;
            background-color: $color-background;
            background-image: url('/img/backgrounds/background.jpg');

            z-index: 6;

            .col-logo {
                top: 20px;
                height: 30px;
            }

            .col-nav {
                margin-top: 0;
            }

            .menu-toggle {
                display: block;
                position: absolute;
                top: 5px;
                left: 10px;
                padding-top: 0;
            }
        }
    }

    @media #{$mq-medium-large-screen} {
        .menu {
            a {
                font-size: 20px;
            }
        }
    }

    @media #{$mq-large-screen} {
        .menu {
            a {
                font-size: 24px;
            }
        }
    }
}
