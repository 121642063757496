.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    .icon-progress {
        position: absolute;
        bottom: 20px;
        left: 20px;

        width: 38px;
        height: 38px;

        transition: color 0.3s ease-out, background-image 0.3s ease-out,
            background-color 0.3s ease-out, border-color 0.3s ease-out, opacity 0.3s ease-out;
        border: 2px solid $color-black;

        background-color: $color-black;
        background-image: url('/img/trips/shapes/pause-red.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;

        color: $color-red;

        cursor: pointer;
        z-index: 1000;

        &.active {
            border-color: $color-red;
            background-color: $color-red;
            background-image: url('/img/trips/shapes/play.svg');
        }

        &.disabled {
            background-color: #4d4d4d;
            background-image: url('/img/trips/shapes/pause.svg');
            cursor: default;
        }

        &.centered {
            top: 50%;
            left: 50%;
            margin-top: -19px;
            margin-left: -19px;
        }

        &.hidden {
            opacity: 0;
        }

        &.video {
            border-color: $color-red;

            &.active {
                background-color: $color-black;
                background-image: url('/img/trips/shapes/play-red.svg');
            }
        }

        &:hover {
            border-color: $color-red;
            // background-color: $color-red;
            // background-image: url('/img/trips/shapes/pause.svg');

            &.active {
                border-color: $color-red;
                background-color: $color-black;
                background-image: url('/img/trips/shapes/play-red.svg');
            }
        }
    }

    .icon-next {
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 38px;
        height: 38px;

        transition: opacity 0.3s ease-out, border-color 0.3s ease-out;
        border: 2px solid $color-black;

        background-color: $color-black;
        background-image: url('/img/trips/shapes/arrow-red.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60%;

        cursor: pointer;
        opacity: 1;
        z-index: 900;

        &.hidden {
            opacity: 0;
        }

        &:hover {
            border-color: $color-red;
        }
    }

    .icon-previous {
        position: absolute;
        bottom: 20px;
        left: 62px;
        width: 38px;
        height: 38px;

        transition: opacity 0.3s ease-out, border-color 0.3s ease-out;
        border: 2px solid $color-black;

        background-color: $color-black;
        background-image: url('/img/trips/shapes/arrow-red-reverse.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60%;

        cursor: pointer;
        opacity: 1;
        z-index: 900;

        &.hidden {
            opacity: 0;
        }

        &:hover {
            border-color: $color-red;
        }
    }

    .menu-button {
        position: absolute;
        top: 135px;
        left: 60px;

        width: 40px;
        height: 40px;

        transition: opacity 0.3s ease-out;

        background-color: transparent;
        background-image: url('/img/icons/burger.svg');
        background-repeat: no-repeat;
        background-position: center 2px;
        background-size: 70%;

        background-color: #555;

        cursor: pointer;
        opacity: 1;
        z-index: 900;

        &.active {
            background-color: $color-red;
        }

        &.hidden {
            opacity: 0;
        }
    }
}
