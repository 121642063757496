.scanner-container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    z-index: 5;
    pointer-events: none;

    .scan-inner {
        width: 100%;
        height: 100%;
        background-image: url('/img/scanner/glow.png');
        background-size: 100%;
    }
}
