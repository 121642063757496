.black-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
}

.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    background-image: none;
    overflow: hidden;

    // .logoContainer {
    //     position: absolute;
    //     top: 15px;
    //     left: 20px;
    //     z-index: 9999;
    // }

    .canvas-container {
        position: absolute;
        top: 0;
        left: 0;

        .canvas-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80px;
            height: 100px;
            margin-top: -50px;
            margin-left: -40px;

            transition: opacity 0.3s ease-out;
            transition-delay: 2s;

            background-image: url('/img/backgrounds/loading.gif');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40px;
            opacity: 0.9;
            z-index: 1;

            pointer-events: none;

            span {
                display: block;
                position: absolute;
                bottom: 0;
                width: 100%;
                color: $color-red;
                font-size: 12px;
                font-weight: bold;
                text-align: center;
            }

            &.hidden {
                transition-delay: 0.3s;
                opacity: 0;
            }
        }
    }

    .logo {
        position: relative;
        top: 20px;
        left: 20px;
        padding: 0;

        h1 {
            max-width: 100px;
            @media #{$mq-medium-screen} {
                max-width: 150px;
            }
        }

        // span {
        // background-color: $color-black;
        // }

        .title,
        .subtitle {
            font-size: 18px;
            @media #{$mq-medium-screen} {
                font-size: 24px;
            }
        }
    }

    canvas {
        display: block;
    }

    img,
    video {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
    }
}
