/**
 * Inputs
 */

.input-text {
    @include placeholderMixin($color-text);

    color: $color-control;
    font-family: $font-home;
    font-size: $font-size-text;
    font-weight: bold;
}

input {
    &[type='checkbox'] {
        display: none;
    }
}
