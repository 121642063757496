.container-content {
    padding: 0 10px;
    margin-top: 0;
}

.container-page {
    .page-title {
        margin-top: 55px;
        margin-bottom: 55px;
    }
}

.container-page-text {
    h4 {
        margin-bottom: 2px;
    }

    p,
    li {
        font-size: 16px;
    }

    ul {
        list-style-type: disc;

        li {
            margin-left: 20px;
            list-style-type: disc;
        }
    }

    .text-section {
        margin-bottom: 16px;
    }

    .text-credit {
        display: block;
        max-width: 200px;
        margin: 8px 0 16px;

        img {
            display: block;
            max-width: 100%;
            margin: 0;
        }
    }
}

.container-list {
    min-height: 50vh;
    padding-top: $padding-large;

    .page-title {
        margin-bottom: $padding-large * 2;
    }
}

.page-title {
    margin: 0 0 $padding-large * 2;
    text-align: center;

    .title {
        font-family: $font-title;
        font-size: $font-size-huge;
        font-weight: 700;
        hyphens: auto;
        line-height: 1;
        word-break: break-word;
    }

    .newsletter-bar {
        display: inline-block;
        margin-bottom: 20px;
        text-decoration: none;

        .inner-bar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .letter {
            width: 39px;
            min-width: 39px;
            height: 25px;
            margin-top: 3px;
            margin-right: 3px;
            margin-bottom: 3px;
            background-image: url('/img/deco/letter-small.png');
            background-repeat: no-repeat;
        }

        .text {
            padding: 2px 10px 3px;
            background-color: $color-control-active;
            color: $color-background-light;
            font-family: $font-home;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-transform: uppercase;
            transition: color 0.1s ease-in-out;
        }

        &:hover {
            .text {
                color: $color-white;
            }
        }

        @media #{$mq-medium-screen} {
            margin-bottom: 0;

            .text {
                font-size: 16px;
            }
        }
    }
}

.container-picture,
.page-section-video .container-video {
    padding: 20px 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    background-image: url('/img/backgrounds/pinboard.jpg');
    background-repeat: repeat;
}

.container-picture {
    position: relative;
    width: 100%;
    min-height: 66vh;
    margin-bottom: $padding-large * 2;

    .container-picture-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: transparent;
        background-origin: content-box;
        background-position: center;
        background-repeat: repeat;
        background-size: contain;
        filter: blur(2px); // GD driver doesnt work somehow
        opacity: 0.6;
    }

    .container-picture-foreground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: transparent;
        background-origin: content-box;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .container-picture-caption {
        position: absolute;
        right: 2px;
        bottom: 0;
        padding: 2px;
        color: $color-hints;
        font-size: 12px;
        font-weight: 700;
        text-align: right;
    }
}

.page-section {
    position: relative;
    margin-bottom: $padding-large * 3;
    font-family: $font-text;

    .section-title {
        padding-top: $font-subtitle-padding-top;
        border-top: 1px solid $color-brown;
        margin-bottom: 10px;
        font-family: $font-title;
        font-size: $font-size-subtitle;
        text-transform: capitalize;

        a {
            color: inherit;
            text-decoration: none;
        }

        &.section-title-alpha {
            color: $color-elements-dark;
            font-family: $font-logo;
            font-size: $font-size-subtitle-alpha;
            text-decoration: none;
        }
    }
}

.page-section-video {
    margin-bottom: 0;

    &.no-captions {
        margin-bottom: $padding-large * 2;
    }
}

.page-section-text,
.page-section-credits {
    p,
    li {
        font-size: 1rem;
    }
}

.page-section-text {
    p {
        margin-bottom: 18px;
    }
}

.page-section-columns {
    a,
    p {
        font-size: $font-size-tiny;
    }

    .col {
        display: block;
        width: 100%;
    }

    ul {
        margin-bottom: 20px;
    }

    a {
        color: $color-text;
        font-weight: bold;
        text-decoration: none;
    }

    p {
        margin-bottom: 3px;
        color: $color-text;
        font-weight: 300;
    }
}

.page-section-rows {
    .row {
        width: 100%;
    }

    .row-half {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
    }

    .row-label {
        padding-right: 20px;
        font-weight: bold;
    }

    .row-value {
        font-size: 16px;
    }
}

@media #{$mq-medium-screen} {
    .container-page {
        .page-title {
            margin-top: 5px;
        }
    }

    .container-picture {
        margin-right: 0;
        margin-bottom: $padding-large * 3;
        margin-left: 0;
    }

    .container-video {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
    }

    .container-list {
        padding-top: $padding-large * 2;

        .page-title {
            margin-bottom: $padding-large * 2;
        }
    }

    .page-section-rows {
        .row-half {
            width: 50%;
        }
    }

    .page-section-columns {
        .cols {
            .col {
                display: table-cell;
                width: 50%;
            }
        }
    }
}
