/*
    List navigation
 */
.toolbar {
    display: table;
    width: 100%;
    margin-bottom: $padding-large;

    &.only-right {
        width: 100px;

        .toolbar-group-right {
            // margin-right: 10px;
        }
    }

    .toolbar-group {
        display: table-cell;
        vertical-align: top;
    }

    .toolbar-group-expand {
        width: 100%;
        // margin-right: 0px;
    }

    .toolbar-group-center {
        padding: 0 10px;
    }

    .toolbar-group-left {
        text-align: right;
    }

    .toolbar-group-right {
        display: block;
        float: right;
        text-align: left;

        @media #{$mq-small-screen} {
            text-align: right;
        }
    }

    .toolbar-item {
        display: inline-block;
        margin-right: 2px;
        transition: background-color 0.15s ease-out;
        cursor: pointer;
        vertical-align: middle;

        &:last-child {
            margin-right: 0;
        }
    }

    &.toolbar-hidden {
        display: none;
        visibility: hidden;
    }

    &.toolbar-top {
        width: auto;
        margin-right: 2px;

        .toolbar-item {
            margin-bottom: 2px;
            padding: 2px 4px;
            transition: $transition-button-text-background;
            background-color: $color-elements-dark;
            color: $color-background;
            font-family: $font-text;
            font-size: $font-size-small;
            font-weight: 700;
            text-transform: uppercase;

            span {
                padding: 0 2px;

                &:first-child {
                    padding-right: 1px;
                }

                &:last-child {
                    padding-left: 1px;
                }
            }

            &.toolbar-icon {
                display: inline-block;
                width: 30px;
                height: 24px;
                margin-right: 2px;
                float: left;
            }

            &.active {
                background-color: $color-control-active;
                color: $color-elements-light;
            }

            &:hover {
                color: $color-elements-light;
            }
        }

        @media #{$mq-small-screen} {
            margin-right: 60px;
        }

        @media #{$mq-large-screen} {
            width: 100%;
            margin-right: 0;
            padding-right: 0;
        }
    }
}

.icon-arrow-up {
    transition: background-image 0.3s ease-out;
    background-image: url('/img/icons/arrow-up-active.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45%;

    &:hover {
        background-image: url('/img/icons/arrow-up-hover.svg');
    }

    &.active {
        background-image: url('/img/icons/arrow-up-hover.svg');
    }
}

.icon-arrow-down {
    transition: background-image 0.3s ease-out;
    background-image: url('/img/icons/arrow-down-active.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45%;

    &:hover {
        background-image: url('/img/icons/arrow-down-hover.svg');
    }

    &.active {
        background-image: url('/img/icons/arrow-down-hover.svg');
    }
}

// The two kinds

.list-section {
    .list-alpha {
        .toolbar {
            @media #{$mq-small-screen} {
                position: absolute;
                top: -46px;
                right: 0px;
                width: 66px;
            }

            @media #{$mq-medium-screen} {
                margin-bottom: 0;
            }

            @media #{$mq-very-large-screen} {
                right: 10px;
            }
        }
    }

    .list-with-view-more {
        .only-right {
            .toolbar {
                .btn-toolbar {
                    & > span {
                        padding-left: 2px;
                    }
                }
            }
        }
    }
}

.list-section,
.container-content-full {
    .toolbar {
        @media #{$mq-small-screen} {
            position: absolute;
            top: 20px;
            right: 0;
        }

        @media #{$mq-medium-screen} {
            margin-bottom: 0;
        }
    }
}
