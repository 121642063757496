.credits {
    font-size: 1rem;
    line-height: 1.3;

    .col {
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }

    .ulist {
        margin-bottom: 10px;
    }

    .ulist-item-li {
        font-weight: bold;

        a {
            color: $color-text;
            text-decoration: none;
        }

        a:hover {
            color: $color-control-active;
        }
    }

    .text-html {
        p {
            margin-bottom: 1rem;
            line-height: 1.3;
        }
    }
}
