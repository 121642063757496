// $fa-font-path: '/vendor/font-awesome/fonts/';
// @import 'font-awesome/scss/font-awesome';

.btn-svg {
    font-size: 0;
}

.icon {
    display: inline-block;
    transition: background-image 0.3s ease-out, background-color 0.3s ease-out;

    &.icon-return-small {
        width: 15px;
        height: 20px;
        background-image: url('/img/icons/return-small.svg');
        background-repeat: no-repeat;
        background-position: center;

        &.red {
            background-image: url('/img/icons/return-small-red.svg');
        }
    }

    &.icon-return-large {
        width: 24px;
        height: 33px;
        background-image: url('/img/icons/return-large.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}

.icon-view-all {
    background-image: url('/img/icons/plus.svg');
    background-repeat: no-repeat;
    background-position: 5px center;
}

.icon-arrow-up {
    background-color: transparent;
    background-image: url('/img/icons/arrow-up-inactive.svg');

    &.active {
        background-image: url('/img/icons/arrow-up-active.svg');
    }
}

.icon-arrow-down {
    background-color: transparent;
    background-image: url('/img/icons/arrow-down-inactive.svg');

    &.active {
        background-image: url('/img/icons/arrow-down-active.svg');
    }
}

.icon-grid-large,
.icon-list-large,
.icon-grid-small,
.icon-list-small {
    background-color: transparent;

    &.active {
        background-color: $color-white;
    }
}
