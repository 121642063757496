.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    .progress {
        display: block;
        position: absolute;
        bottom: 6px;
        left: 0;
        margin: 0;
        padding: 0;

        color: $color-red;
        z-index: 9999;
    }

    .progress-bar-container {
        vertical-align: top;
    }

    .progress-bar {
        position: absolute;
        width: 100%;
        min-height: 6px;
        transition: height 0.1s ease-out;
        border-top: 1px solid $color-black;
        background-color: #4d4d4d;
    }

    .progress-bar-loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        min-height: 6px;
        transition: height 0.1s ease-out;
        background-color: #808080;
    }

    .progress-bar-progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        min-height: 6px;
        transition: height 0.1s ease-out;
        background-color: $color-red;
    }

    .progress-bar-stripe {
        position: absolute;
        top: 0;
        left: 20%;
        width: 3px;
        min-height: 6px;
        transition: height 0.1s ease-out;
        background-color: $color-text;
    }
}
