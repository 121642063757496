.layout {
    position: relative;

    /**
     * Containers
     */
    .container {
        // width: 100%;
        max-width: 960px;
        box-sizing: border-box;
        padding: 0 10px;
        margin: 0 auto;
    }

    .container-content-full {
        overflow: hidden;

        .container {
            padding: 0 10px;
        }
    }

    .container-relative {
        position: relative;
    }

    .modals {
        .popup {
            position: fixed;
            z-index: 100;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;

            .inner {
                position: relative;
                z-index: 101;
                margin-right: 10px;
                margin-left: 10px;
            }

            .wrapper {
                display: flex;
                overflow: scroll-y;
                max-width: 100%;
                max-height: 100%;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 10px;
                background-color: $color-control-active;
                text-decoration: none;

                @media #{$mq-medium-screen} {
                    max-width: 480px;
                    flex-direction: row;
                    padding: 15px 20px;
                    padding-right: 40px;
                }
            }

            .link {
                color: $color-text-pink;
                text-decoration: none;
                transition: color 0.1s ease-in-out;

                &:hover {
                    strong {
                        color: $color-white;
                    }
                }
            }

            .background {
                @extend %reset-button;

                position: absolute;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(5px);
                background-color: $color-background-light;
                opacity: 0.8;
            }

            .title, .description {
                padding: 0;
                margin: 0 0 10px;
                font-family: $font-title;
            }

            .title {
                font-weight: normal;
                line-height: 1;
            }

            .description {
                font-family: $font-home;
                font-size: 1rem;
                line-height: 1.2;

                strong {
                    padding: 2px 6px;
                    background-color: $color-black;
                }

                a {
                    padding: 2px 6px;
                    background-color: $color-black;
                    color: $color-text-pink;
                    text-decoration: none;
                }
            }

            .left {
                max-width: 160px;
                margin-top: 8px;
                margin-right: 10px;
                margin-bottom: 8px;
                object-fit: contain;

                @media #{$mq-medium-screen} {
                    margin-right: 15px;
                }
            }

            .right {
                margin-right: 20px;
            }

            .close {
                @extend %reset-button;

                position: absolute;
                z-index: 102;
                top: 6px;
                right: 6px;
                display: block;
                width: 30px;
                height: 30px;
                padding: 5px;
                background-image: url('/img/deco/close-small.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                color: $color-text-pink;

                // filter: invert(100%);
                transition: filter 0.1s ease-in-out;

                @media #{$mq-medium-screen} {
                    top: 11px;
                    right: 11px;
                }

                &:hover {
                    filter: invert(20%);
                }
            }
        }
    }

    /* a,
    .toolbar-item {
        transition: color .15s ease-in, background-color .15s ease-in;
    }

    .btn {
        * {
            transition: color .15s ease-in, background-color .15s ease-in, border 0.15s ease-in;
        }
    }
    */

    .list-item-grid {
        // transform: rotate(0deg);
        //
        // &:nth-child(2n) {
        //     transform: rotate(0.2deg);
        // }
        //
        // &:nth-child(3n) {
        //     transform: rotate(-0.4deg);
        // }
        //
        // &:nth-child(5n) {
        //     transform: rotate(-0.3deg);
        // }
        //
        // &:nth-child(7n) {
        //     transform: rotate(-0.2deg);
        // }
        //
        // &:nth-child(9n) {
        //     transform: rotate(0.4deg);
        // }
    }

    .list-item-grid:hover {
        // transform: rotate(0.5deg);

        .title {
            strong:first-child {
                color: $color-highlights;

                // color: $color-background;
            }
        }

        .subtitle {
            color: $color-highlights;

            // color: $color-background;
        }

        .image-container {
            filter: contrast(130%);

            // transform: scale(1.1);
        }

        .code {
            color: $color-black;
        }
    }

    .list-item-grid {
        transition: transform 0.2s ease-out;

        .title {
            strong:first-child {
                transition: color 0.2s ease-out;
            }
        }

        .subtitle {
            transition: color 0.2s ease-out;
        }

        .image-container {
            transition: filter 0.2s ease-out;
        }
    }

    .col-nav {
        .container {
            padding: 0;
        }
    }

    .left-side {
        z-index: 1;
    }

    .right-side {
        z-index: 2;
        transition: padding 0.2s ease;
    }

    .title-page {
        margin-bottom: 40px;

        h1 {
            color: $color-text;
            font-family: $font-title;
            font-size: $font-size-huge;
            line-height: 1;
        }
    }

    .children {
        min-height: 100vh;
    }

    &.header-collapsed {
        .left-side {
            padding-bottom: 0;
        }
    }

    @media #{$mq-small-screen} {
        .children,
        .last-children {
            margin-top: 0;
        }

        .left-side {
            position: static;
            width: 100%;
            height: auto;
        }
    }

    @media #{$mq-medium-screen} {
        .children,
        .last-children {
            margin-top: $padding-large * 4;
        }

        .container {
            max-width: 100%;
            padding-left: 280px;
        }

        .col-nav {
            .container {
                padding-left: 280px;
            }
        }

        .container-content-full {
            .page-title {
                h1 {
                    padding: 0;
                    padding-top: 60px;
                }
            }

            .container {
                max-width: 90%;
            }
        }

        .left-side {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            width: 240px;
            padding: 20px;
        }

        &.sidebar-closed {
            .container {
                padding-left: 70px;
            }

            .container-content-full {
                .container {
                    padding-left: 0;
                }
            }

            .left-side {
                width: 30px;
            }

            .col-nav {
                .container {
                    padding-left: 70px;
                }
            }

            &.header-collapsed {
                .logo {
                    top: -2px;
                    padding-left: 70px;

                    .menu-toggle {
                        position: absolute;
                        top: 5px;
                        left: 20px;
                    }
                }

                .menu {
                    margin-left: 150px;
                }
            }
        }

        &.header-collapsed {
            .logo {
                top: -1px;
                padding-left: 70px;

                .menu-toggle {
                    position: absolute;
                    top: 5px;
                    left: 20px;
                }
            }

            .align-padding-left {
                .container-relative {
                    // padding-left: 0;
                }
            }
        }
    }

    @media #{$mq-medium-large-screen} {
        .container {
            max-width: 100%;
            padding-left: 280px;
        }

        .col-nav {
            .container {
                padding-left: 280px;
            }
        }

        .container-content-full {
            .page-title {
                h1 {
                    padding: 0;
                    padding-top: 60px;
                }
            }

            .container {
                max-width: 80%;
            }
        }

        &.sidebar-closed {
            .container {
                padding-left: 70px;
            }

            .container-content-full {
                .container {
                    padding-left: 0;
                }
            }

            .col-nav {
                .container {
                    padding-left: 70px;
                }
            }

            &.header-collapsed {
                .logo {
                    top: -1px;
                    padding-left: 70px;

                    .menu-toggle {
                        position: absolute;
                        top: 5px;
                        left: 20px;
                    }
                }

                .menu {
                    margin-left: 130px;
                }
            }
        }

        &.header-collapsed {
            .logo {
                top: -1px;
                padding-left: 70px;

                .menu-toggle {
                    position: absolute;
                    top: 5px;
                    left: 20px;
                }
            }

            .align-padding-left {
                .container-relative {
                    padding-left: 280px;
                }
            }
        }

        .container-content {
            &.container-content-full {
                // top: $padding-large * 8;
            }
        }
    }

    @media #{$mq-large-screen} {
        .container {
            max-width: 100%;
            padding-left: 280px;
        }

        .col-nav {
            .container {
                padding-left: 280px;
            }
        }

        &.sidebar-closed {
            .container {
                padding-left: 70px;
            }

            .container-content-full {
                .container {
                    padding-left: 0;
                }
            }

            .col-nav {
                .container {
                    padding-left: 70px;
                }
            }
        }
    }

    @media #{$mq-laptop-screen} {
        .container {
            max-width: 1150px;
        }

        &.sidebar-closed {
            .container {
                padding-left: 10px;
            }

            .header .container {
                padding-left: 10px;
            }
        }
    }

    @media #{$mq-very-large-screen} {
        // No more padding

        .container {
            max-width: 1030px;
            padding-right: 0;
            padding-left: 0;
        }

        .col-nav {
            .container {
                padding-left: 0;
            }
        }

        &.header-collapsed {
            .align-padding-left {
                .container-relative {
                    padding-left: 0;
                }
            }
        }
    }
}

// Preload important menu state images to avoid jumps

body {
    &::after {
        position: absolute;
        z-index: -1;
        overflow: hidden;
        width: 0;
        height: 0;
        content: url('/img/icons/forward.svg'), url('/img/icons/back.svg'),
            url('/img/icons/burger-close.svg'), url('/img/icons/burger.svg'),
            url('/img/icons/search.svg');
    }
}

.cookies {
    position: fixed;
    z-index: 101;
    right: 0;
    bottom: 0;
    background-color: $color-control-active;
    box-shadow: -2px -1px 10px rgb(0 0 0 / 50%);

    @media #{$mq-medium-screen} {
        max-width: 50%;
    }

    .inner {
        position: relative;
        z-index: 101;
        padding: 10px 40px 10px 10px;
        margin: 0 10px;
        color: $color-white;
    }

    .top {
        margin-bottom: 10px;
    }

    h3, p, a {
        color: $color-white;
    }

    h3 {
        margin-bottom: 5px;
    }

    p, a, span, label {
        font-size: 14px;
    }

    input[type="checkbox"] {
        display: inline-block;
        margin-right: 5px;
    }

    .choice {
        margin-bottom: 5px;
    }

    .checkbox-label {
        font-size: 16px;
        font-weight: bold;
    }

    .button {
        @extend %reset-button;

        padding: 5px;
        margin: 0 10px 10px 0;
        background-color: $color-orange;
        font-weight: bold;

        &.mt-5 {
            margin-top: 5px;
        }
    }

    .close {
        @extend %reset-button;

        position: absolute;
        z-index: 102;
        top: 6px;
        right: 6px;
        display: block;
        width: 30px;
        height: 30px;
        padding: 5px;
        background-image: url('/img/deco/close-small.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: $color-text-pink;

        // filter: invert(100%);
        transition: filter 0.1s ease-in-out;

        @media #{$mq-medium-screen} {
            top: 11px;
            right: 11px;
        }

        &:hover {
            filter: invert(20%);
        }
    }
}
