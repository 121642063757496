/**
 * Gallery
 */

$gallery-arrow-width: 40px;

.container-gallery {
    position: relative;
    width: 100%;
}

.caption {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;

    p {
        padding: 0 10px;
    }

    span {
        font-weight: 300;
    }
}

.gallery-caption {
    position: relative;
    bottom: 0;
    min-height: 50px;
    padding: 30px 0 0;

    p {
        padding: 0;
    }
}

.gallery {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:-webkit-full-screen {
        width: 100%;
        height: 100%;
    }
}

.gallery-viewer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/img/backgrounds/pinboard.jpg');
    background-repeat: repeat;
    overflow: hidden;

    .dragmove {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        cursor: move;

        .image {
            width: 100%;
            height: 100%;
            border: 0;

            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            cursor: pointer;
            appearance: none;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
                background-color: $color-elements-light;
            }
        }
    }
}

.gallery-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;

    .btn {
        display: block;
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
        padding: 0;
        background-color: $color-control;
        background-repeat: no-repeat;
        background-position: center;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .btn-fullscreen {
        background-image: url('/img/gallery/fullscreen.svg');
    }

    .btn-zoom-in {
        background-image: url('/img/gallery/zoom-in.svg');
    }

    .btn-zoom-out {
        background-image: url('/img/gallery/zoom-out.svg');
    }

    .btn-rotate {
        background-image: url('/img/gallery/rotate.svg');
    }
}

.gallery-nav {
    position: relative;
    top: 100%;
    width: 100%;
    margin-top: 1px;

    .col {
        height: 50px;
        font-size: 0;
        white-space: nowrap;
    }

    .btn {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 15px 0;
        border-right: solid 2px $color-elements-gray;

        color: $color-elements-dark;
        font-size: 20px;
        line-height: 0.75em;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        vertical-align: top;
        box-sizing: border-box;

        &:hover {
            background-color: $color-semitransparent;
        }

        &:last-child {
            border-right: 0;
        }

        &.btn-arrow {
            transition: background-image 0.3s ease-out;
            background-color: $color-control;

            &:hover {
                background-color: $color-control-active;
            }
        }

        &.btn-arrow-left {
            background-image: url('/img/gallery/arrow-left.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        &.btn-arrow-right {
            background-image: url('/img/gallery/arrow-right.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .gallery-menu {
        width: 100%;
        padding: 0 3px;
        background-image: url('/img/backgrounds/pinboard.jpg');
        background-repeat: repeat;
        font-size: 0;
        text-align: center;
        white-space: nowrap;

        overflow: hidden;
        box-sizing: border-box;
        background-origin: content-box;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .transitionable-view {
        display: inline-block;
    }

    .menu-item {
        position: relative;
        width: 50px;
        height: 50px;
        transition: background-color $transition-time ease-out;
        box-sizing: border-box;

        a,
        button {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }

        .image {
            display: block;
            position: absolute;
            top: 10%;
            left: 10%;
            width: 80%;
            height: 80%;
            transition: all $transition-time ease-out;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &.current {
            background-color: $color-semitransparent;
        }
    }
}
