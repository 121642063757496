/**
 * Splash page
 */

.splash {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0;
    overflow: hidden;

    .animation-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-black;
        background-image: url('/img/backgrounds/mediumBackground.jpg');
        background-repeat: repeat;
        background-position: top left;
        background-size: 100%;
    }
}

.splash-content {
    opacity: 0;
}

.splash-container {
    display: block;
    position: absolute;
    top: 25px;
    left: 25px;
    width: 400px;
    text-align: left;
    z-index: 10;
}

.splash-logo {
    margin-bottom: 32px;
    font-family: $font-logo;
    font-size: 32px;
    line-height: 1.05;
    opacity: 0;
}

.splash-subtitle {
    font-family: $font-text;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 0;
}

@media #{$mq-small-screen} {
    .splash-container {
        position: absolute;
        top: 50px;
        left: 50px;
    }
}

@media #{$mq-medium-screen} {
    .splash-container {
        position: absolute;
        top: 100px;
        left: 100px;
    }

    .splash-logo {
        margin-bottom: 48px;
        font-size: $font-size-very-large;
    }

    .splash-subtitle {
        font-size: 16px;
    }
}

@media #{$mq-large-screen} {
    .splash-container {
        position: absolute;
        top: 150px;
        left: 150px;
    }
}
