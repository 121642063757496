.pagination {

    li {
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    a,
    span {
        display: inline-block;
        padding: 5px 10px;
    }

    a {
        border: solid 1px $color-elements-dark;
        color: inherit;
        text-decoration: none;
    }

    .active {
        span {
            background: $color-elements-dark;
            color: $color-elements-light;
        }
    }
}
