/*
 * Colors
 */
$color-black: #000;
$color-white: #fff;
$color-text: #000;
$color-control: #000;
$color-control-active: #33c; // #f60;
$color-highlights: #c36;
$color-background: #8f785e;
$color-background-light: #D0C6BC;
$color-text-pink: #FDD;
$color-hints: #ccc;
$color-semitransparent: rgb(0 0 0 / 35%);
$color-brown: #423333;
$color-red: #f00;
$color-orange: #f63;
$color-yellow: #fc3;
$color-shadow: rgb(0 0 0 / 55%);
$color-elements-light: #fff;
$color-elements-dark: #000;
$color-elements-gray: #3f3f3f;

/**
 * Fonts
 */
$font-logo: 'Berlingske Serif Stbold', serif;
$font-title: 'VT323', monospace;
$font-menu: 'VT323', monospace;
$font-text: 'Roboto', sans-serif;
$font-home: 'Share', sans-serif;
$font-size-huge: 60px;
$font-size-very-large: 48px;
$font-size-logo: 24px;
$font-size-title: 50px;
$font-size-subtitle: 40px;
$font-size-subtitle-alpha: 30px;
$font-size-text: 20px;
$font-size-small: 18px;
$font-size-tiny: 14px;
$font-subtitle-padding-top: 4px;

/**
 * Padding
 */

$padding-small: 5px;
$padding-default: 10px;
$padding-large: 20px;

/**
 * Screen size
 */
$small-screen: 600px;
$medium-screen: 768px; // Big changes happen here
$medium-large-screen: 890px;
$large-screen: 1060px;
$laptop-screen: 1280px; // Only to max-width the layout
$very-large-screen: 1600px;
$huge-screen: 1800px;

/**
 * Media queries
 */
$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-medium-large-screen: 'only screen and (min-width: #{$medium-large-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-laptop-screen: 'only screen and (min-width: #{$laptop-screen})';
$mq-very-large-screen: 'only screen and (min-width: #{$very-large-screen})';
$mq-huge-screen: 'only screen and (min-width: #{$huge-screen})';

/**
 * Transitions
 */
$transition-time-slow: 0.4s;
$transition-time: 0.33s;
$transition-time-fast: 0.2s;
$transition-text: color 0.05s ease-out;
$transition-type: ease-in-out;
$transition-button-text: color 0.15s ease-out;
$transition-button-text-background: color 0.15s ease-out, background-color 0.15s ease-out;
