.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    .scene-menu-shadow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-shadow;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
    }

    .scene-menu {
        position: absolute;
        top: 100px;
        right: 150px;
        bottom: 100px;
        left: 150px;

        font-family: $font-title;
        z-index: 1000;

        &.title-only {
            display: table;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            pointer-events: none;

            .inner-scene-menu {
                display: table-cell;
                vertical-align: middle;
            }
        }

        &.full {
            .title {
                position: relative;
                top: 50%;
                margin-top: -25px;
            }
        }

        .title {
            text-align: center;
        }

        h2 {
            display: inline-block;
            position: relative;
            padding: 2px 25px;

            color: $color-text;
            font-family: $font-logo;
            font-size: 60px;
            font-weight: 700;

            z-index: 999;

            a {
                color: $color-red;
                text-decoration: none;
            }

            &.scene {
                background-color: $color-text;
                color: $color-red;
                font-family: $font-logo;
                font-size: 40px;
                font-weight: 400;
                z-index: 9999;
            }
        }

        .inner-scene-menu {
            width: 100%;
            height: 100%;
        }

        .scene-menu-link {
            font-family: $font-title;
            line-height: 1;
            text-align: left;

            .scene-menu-item {
                position: absolute;
            }

            p {
                position: relative;
                transition: border-color 0.15s ease-out;
            }

            span {
                transition: color 0.15s ease-out, background-color 0.15s ease-out;
                border: 3px solid transparent;
            }

            .background,
            .foreground {
                display: inline-block;
                margin: 0;
                padding: 4px 10px;

                transition: background-image 0.3s ease-out, background-color 0.3s ease-out;
                background-color: transparent;
                background-image: url('/img/backgrounds/mediumBackground.jpg');
                background-repeat: repeat;
                color: $color-text;

                font-size: 19px;

                @media #{$mq-laptop-screen} {
                    font-size: 22px;
                }

                @media #{$mq-huge-screen} {
                    font-size: 24px;
                }
            }

            .background {
                position: absolute;
                top: 0;
                left: 0;
                transition: opacity 0.3s ease-out;
                background-color: $color-red;
                background-image: none;
                opacity: 0;
            }

            &:hover {
                .background {
                    opacity: 1;
                }
            }

            &.finished {
                span {
                    border: 3px solid $color-red;
                    background-color: $color-text;
                    background-image: none;
                    color: $color-red;
                }
            }

            &.active {
                span {
                    border: 3px solid $color-yellow;
                    background-color: $color-text;
                    background-image: none;
                    color: $color-yellow;
                }
            }
        }
    }
}
