/**
 * Buttons
 */

%reset-button {
    position: relative;
    display: inline-block;
    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font-family: $font-text;
}

.btn {
    display: inline-block;
    box-sizing: border-box;
    border: 0;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
}

.btn-toolbar {
    display: inline-block;
    border: 1px solid $color-text;
    margin-bottom: 5px;
    background-color: $color-text;
    color: $color-background;
    font-size: $font-size-small;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: $transition-button-text-background;

    span {
        display: inline-block;
        padding: 3px;
    }

    &.active {
        border-color: $color-control-active;
        background-color: $color-control-active;
        color: $color-text;
    }

    &:hover {
        border-color: $color-elements-light;
        background-color: $color-elements-light;
        color: $color-text;
    }
}

.btn-svg {
    padding: 0;
    border: 0;
    margin: 0;
    background-color: transparent;
    background-image: none;

    &.active,
    &:active,
    &:hover {
        background-color: transparent;
    }
}

%btn-with-icon {
    width: 25px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
}

.btn-with-icon {
    @extend %btn-with-icon;
}

.btn-with-icon-and-label {
    @extend %btn-with-icon;

    width: 96px;
    height: 20px;
    border: 0;
    margin-right: 10px;
    background-color: transparent;
    background-size: 10px 10px;

    & > span {
        display: block;
        width: 100%;
        height: 20px;
        padding: 0;
        padding-right: 10px;
        margin: 0;
        color: $color-text;

        // font-family: $font-menu;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        text-transform: uppercase;

        span {
            display: block;
            padding: 0 5px;

            // width: 100%;
            border: 1px solid $color-text;

            // padding-left: 2px;
        }
    }

    &:hover {
        background-color: $color-control-active;
        background-image: url('/img/icons/plus-active.svg');

        & > span > span {
            color: $color-elements-light;
        }
    }

    @media #{$mq-medium-screen} {
        width: 90px;
    }
}

.btn-playback {
    width: 64px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;

    &.btn-playback-playing {
        background-image: url('/img/player/pause.svg');
    }

    &.btn-playback-paused {
        background-image: url('/img/player/play.svg');
    }

    &.btn-playback-loading {
        background-image: none; // url('/img/player/loading.svg');
    }
}

.btn-close {
    width: 30px;
    height: 30px;
    background-image: url('/img/icons/close.svg');
    background-position: center;
    background-repeat: no-repeat;

    &.empty {
        background-image: none;
    }
}

.btn-submit {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-image: url('/img/icons/search.svg');
    background-position: 1px center;
    background-repeat: no-repeat;
    pointer-events: all;
}
