.search {
    display: inline-block;
    position: relative;
    border-top: 1px solid $color-brown;
    border-right: 1px solid $color-brown;
    border-bottom: 1px solid $color-brown;
    border-left: 1px solid $color-brown;

    font-size: 0;
    box-sizing: border-box;

    &.active {
        border-top: 1px solid $color-control-active;
        border-right: 1px solid $color-control-active;
        border-bottom: 1px solid $color-control-active;
        border-left: 1px solid $color-control-active;

        .input-text {
            border-left: solid 1px $color-control-active;
            color: $color-control-active;
        }

        .btn-submit {
            background-image: url('/img/icons/search-blue.svg');
        }

        .btn-close {
            background-image: url('/img/icons/close-blue.svg');
        }
    }

    &.expanded {
        @media #{$mq-medium-screen} {
            form {
                background-image: url('/img/backgrounds/background.jpg');
                background-repeat: no-repeat;
            }
        }

        @media #{$mq-large-screen} {
            form {
                background-image: none;
                background-repeat: no-repeat;
            }
        }
    }

    .input-text {
        display: none;
        width: 160px;
        height: 30px;
        padding: 0 10px;

        border-left: solid 1px $color-text;
        background-color: transparent;
        color: $color-black;
        box-sizing: border-box;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $color-black;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $color-black;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: $color-black;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: $color-black;
        }
        &::placeholder {
            color: $color-black;
        }
    }

    @media #{$mq-medium-screen} {
        margin-right: 10px;
    }

    @media #{$mq-large-screen} {
        .input-text {
            display: inline-block;
        }
    }
}

.no-result,
.no-results {
    color: $color-text;
    font-family: $font-menu;
}

.no-result {
    display: none;
    visibility: hidden;
}

.container-list-with-sections {
    .no-result + .no-result + .no-result + .no-result {
        display: inline;
        visibility: visible;
    }
}
