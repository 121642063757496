.list {
    margin-bottom: 10px;
    font-size: 0;
}

.list-items {
    display: table;
    width: 100%;

    &.cards {
        .list-table-cell {
            display: inline-block;
            width: 100%;
            vertical-align: top;

            @media #{$mq-small-screen} {
                width: 50%;

                &:nth-child(1n),
                &:nth-child(4n) {
                    .list-item {
                        padding-right: 5px;
                        padding-left: 0;
                    }
                }

                &:nth-child(2n) {
                    .list-item {
                        padding-right: 0;
                        padding-left: 5px;
                    }
                }
            }

            @media #{$mq-very-large-screen} {
                width: 33.33%;

                &:nth-child(2n) {
                    .list-item {
                        padding-right: 5px;
                        padding-left: 5px;
                    }
                }

                &:nth-child(3n) {
                    .list-item {
                        padding-right: 0;
                        padding-left: 10px;
                    }
                }

                &:nth-child(1n),
                &:nth-child(4n) {
                    .list-item {
                        padding-right: 10px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.subtitle-alpha {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $color-brown;
    font-family: $font-logo;
    font-weight: bold;

    h1 {
        font-size: 30px;
    }
}

.list-section {
    padding-bottom: 0;
    clear: both;

    .section-title {
        padding-top: $font-subtitle-padding-top;
        border-top: 1px solid $color-brown;

        background-image: url('/img/deco/lines.svg');
        background-repeat: no-repeat;
        background-position: 120% center;

        font-family: $font-title;
        font-size: $font-size-subtitle;

        a {
            &:hover {
                color: $color-control-active;
            }
        }

        @media #{$mq-very-large-screen} {
            margin-right: 10px;
        }
    }
}

.container-page {
    .page-section {
        .only-right {
            @media #{$mq-very-large-screen} {
                right: 10px;
            }
        }

        .section-title {
            @media #{$mq-very-large-screen} {
                margin-right: 10px;
            }
        }
    }
}

.list-item {
    width: 100%;
    max-width: 100%;
}

.list-section-loading-indicator {
    display: block;
    width: 100%;

    .inner {
        min-height: 21px;
        padding-top: 20px;

        svg {
            display: block;
            margin: 0 auto;
            transform-origin: center center;
        }
    }
}

.list-section-loading {
    padding-bottom: 0;

    .list-item-loading {
        position: relative;
        padding-bottom: 10px;

        .wrap {
            display: block;
            padding: 0 1% 1% 0;
            border: 2px solid $color-brown;
            background-image: url('/img/backgrounds/pinboard.jpg');
            background-repeat: repeat;
            opacity: 0.6;
        }

        .loading-grid-fill {
            height: 80vh;
        }

        &.list-item-small {
            .loading-grid-fill {
                height: 75px;
                padding-bottom: 0;
            }
        }

        &.list-item-vertical {
            margin: 0;
            margin-bottom: 20px;
            padding: 0;

            clear: both;
            box-sizing: border-box;

            .wrap {
                width: 100%;
                margin: 0;
                padding: 0;

                .container-picture {
                    width: 100%;
                    height: 0;
                    min-height: 0 !important;
                    margin: 0;
                    padding-bottom: 50%;
                    background: none;
                }
            }
        }
    }
}

@keyframes animatedBackground {
    from {
        background-size: 3000%;
        opacity: 0.2;
    }

    to {
        display: none;
        background-size: 100%;
        opacity: 0.4;
        visibility: hidden;
    }
}

.list-item-vertical {
    display: block;
    padding-bottom: 20px;
    box-sizing: border-box;

    a {
        color: $color-text;
        text-decoration: none;

        &:hover {
            .left .inside strong {
                background-color: $color-control-active;
                color: $color-elements-light;
            }
        }
    }

    .left {
        position: relative;
        width: 100%;
        background-color: $color-elements-light;
        background-image: url('/img/backgrounds/whitenoise.jpg');
        box-sizing: border-box;

        .image-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            box-sizing: border-box;
        }

        .container-picture {
            height: 0;
            min-height: 0;
            margin-bottom: 0;
            padding-bottom: 50%;
        }

        .inside {
            position: absolute;
            right: 0;
            bottom: 10px;
            left: 0;
            padding: 0;
            transition: $transition-text;
            color: $color-hints;
            box-sizing: border-box;

            strong {
                display: inline-block;
                margin: 0 auto;
                padding: 4px 8px;
                transition: background-color 0.15s ease-out, color 0.15s ease-out;
                background-color: $color-text;
                font-weight: 400;
                text-transform: uppercase;
            }

            @media #{$mq-medium-screen} {
                padding: 10px 20px;
            }
        }
    }

    .overflow,
    .small-subtitle {
        display: block;
        text-decoration: none;
    }

    .overflow {
        font-family: $font-menu;
        font-size: 28px;
        line-height: 1;
    }

    .caption {
        color: $color-elements-dark;
    }

    .code {
        display: none;
        visibility: hidden;
    }

    .small-subtitle {
        font-size: $font-size-tiny;
        font-weight: normal;
    }

    &.list-item-artist {
        .left {
            display: none;
            visibility: hidden;
        }

        .right {
            padding-bottom: 0;
            padding-left: 0;
        }
    }
}

.list-item-grid {
    position: relative;
    width: 100%;
    max-width: 100%;

    padding-bottom: $padding-default;
    float: none;
    box-sizing: border-box;

    @media #{$mq-small-screen} {
        padding-right: $padding-default;
    }

    a {
        height: 100%;
        padding: 0;
        padding-bottom: 0%;
        color: inherit;
        text-decoration: none;
        overflow: hidden;
        box-sizing: border-box;
    }

    .image,
    .caption,
    .title,
    .subtitle {
        display: block;
    }

    .title,
    .subtitle,
    .small-subtitle {
        text-overflow: ellipsis;
        overflow: hidden;

        &:empty {
            &::after {
                content: '.';
                visibility: hidden;
            }
        }
    }

    .image {
        display: block;
        min-width: 100%;
        max-width: 100%;
        padding: 0;
        background-image: url('/img/backgrounds/whitenoise.jpg');
        background-repeat: repeat;
    }

    .caption-container {
        max-height: 75px;
        background-image: url('/img/backgrounds/whitenoise.jpg');
        background-repeat: repeat;
        overflow: hidden;
    }

    .caption {
        display: table;
        width: 100%;
        height: 75px;
        padding: 0;
        table-layout: fixed;

        .title,
        .code {
            display: table-cell;
            margin: 0;
            padding: 0 10px;
            overflow: hidden;
            vertical-align: middle;
        }

        .title {
            width: 75%;
            font-family: $font-text;
            font-size: $font-size-small;
            font-weight: bold;
            line-height: 1.15;
            overflow: hidden;

            .small-subtitle,
            .overflow {
                display: inline-block;
                width: 100%;
                text-overflow: ellipsis;
            }

            .overflow {
                display: block;
                display: -webkit-box;
            }

            .small-subtitle {
                font-weight: normal;
                white-space: nowrap;
            }
        }

        .code {
            width: 25%;
            padding-right: 15px;
            transition: color 0.3s ease-out;
            color: $color-hints;
            font-family: $font-logo;
            font-size: $font-size-text;
            text-align: right;
            text-transform: uppercase;
        }
    }

    .overflow {
        /* Ugly webkit hack, clamp.js improves to this */
        @include clampMixin(3);
        max-height: 75px;
        overflow: hidden;
    }

    .subtitle-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 37px;
        background-image: url('/img/backgrounds/whitenoise.jpg');
        background-repeat: repeat;
    }

    .subtitle {
        position: relative;
        padding: $padding-small $padding-default;

        color: $color-highlights;
        font-family: $font-title;
        font-size: $font-size-text;
    }

    .small-subtitle {
        display: none;
        color: $color-highlights;
        font-family: $font-title;
        font-size: $font-size-text;
        visibility: hidden;
    }

    .shadow {
        position: relative;
        background-image: url('/img/backgrounds/whitenoise.jpg');
        background-repeat: repeat;

        &::after {
            display: block;
            padding-bottom: 94%;
            content: '';
        }

        & > a {
            position: absolute;
        }

        &:hover {
        }
    }

    .image-container {
        display: block;
        position: absolute;
        top: 75px;
        right: 0;
        bottom: 37px;
        left: 0;

        background: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        overflow: hidden;
    }

    &.list-item-large {
        .caption {
            .small-subtitle {
                display: none;
                visibility: hidden;
            }
        }

        .subtitle {
            white-space: nowrap;
        }
    }

    &.list-item-small {
        .shadow {
            &::after {
                padding-bottom: 0;
            }

            & > a {
                position: relative;
            }
        }

        .image {
            display: none;
            visibility: hidden;
        }

        .caption {
            .title {
                line-height: 1;
            }

            .small-subtitle {
                display: inline-block;
                visibility: visible;
            }

            .overflow {
                padding-bottom: 2px;
            }

            strong {
                line-height: 1;
            }
        }

        .subtitle {
            display: none;
            visibility: hidden;
        }
    }

    @media #{$mq-small-screen} {
        // width: 50%;
        // padding-right: $padding-default;
        // float: left;

        // &:nth-child(2n) {
        //     padding-right: 0;
        //     padding-left: 5px;
        // }
    }

    @media #{$mq-medium-screen} {
        // width: 50%;
    }

    @media #{$mq-large-screen} {
        // width: 50%;
    }

    @media #{$mq-very-large-screen} {
        // width: 33.33%;
        // &,
        // &:nth-child(2n) {
        //     padding-right: 6px;
        //     padding-left: 0;
        // }
        //
        // &:nth-child(3n - 1) {
        //     padding-right: 3px;
        //     padding-left: 3px;
        // }
        //
        // &:nth-child(3n) {
        //     display: block;
        //     padding-right: 0;
        //     padding-left: 6px;
        //     visibility: visible;
        // }
        //
        // .container-list-with-sections {
        //     .list-item-grid {
        //         &:nth-child(3) {
        //             display: none;
        //             visibility: hidden;
        //         }
        //     }
        // }
    }
}

// The full relayout with smaller items
// Do not confuse with item-small

.list-items-small {
    .list-item-grid {
        width: 100%;
        max-width: 100%;

        .caption {
            height: 75px;
            min-height: 75px;
            max-height: 75px;
        }
    }

    @media #{$mq-small-screen} {
        .list-item-grid {
            // width: 50%;
        }
    }

    @media #{$mq-large-screen} {
        .list-item-grid {
            // width: 33.33%;
            // &,
            // &:nth-child(2n) {
            //     padding-right: 6px;
            //     padding-left: 0;
            // }
            //
            // &:nth-child(3n - 1) {
            //     padding-right: 3px;
            //     padding-left: 3px;
            // }
            //
            // &:nth-child(3n) {
            //     padding-right: 0;
            //     padding-left: 6px;
            // }
        }
    }
}

.container-list-with-sections {
    @media #{$mq-medium-screen} {
        .list-item-grid {
            &:nth-child(3) {
                display: none;
                visibility: hidden;
            }
        }

        .list-items-small {
            .list-item-grid {
                &:nth-child(3) {
                    display: block;
                    visibility: visible;
                }
            }
        }
    }

    @media #{$mq-very-large-screen} {
        .list-item-grid {
            &:nth-child(3) {
                display: block;
                visibility: visible;
            }
        }

        .list-items-small {
            .list-item-grid {
                &:nth-child(3) {
                    display: block;
                    visibility: visible;
                }
            }
        }
    }
}

.container-content-full {
    .list-item-grid {
        &.list-item-video,
        &.list-item-audio {
            .overlay-play-button {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 62px;
                height: 62px * 0.625;

                margin-top: -15.5px;
                margin-left: -31px;

                background-image: url('/img/player/play.svg');
                background-repeat: no-repeat;
                background-size: 100%;
            }
        }
    }
}
