.slideshow {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .slideshow-controls,
    .slideshow-item-caption {
        display: none;
        visibility: hidden;
    }

    @media #{$mq-medium-screen} {
        display: block;
        position: static;

        .slideshow-controls {
            display: block;
            visibility: visible;
        }

        .slideshow-item-caption {
            display: inline-block;
            visibility: visible;
        }
    }

    .btn-arrow {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        background-color: $color-control;
        background-repeat: no-repeat;
        background-position: center;

        &.btn-arrow-next {
            background-image: url('/img/icons/arrow-right-triangle-inactive.svg');
        }

        &.btn-arrow-prev {
            background-image: url('/img/icons/arrow-left-triangle-inactive.svg');
        }
    }
}

.slideshow-item {
    transition: opacity 5s ease-out;
    opacity: 0;

    &.current {
        opacity: 1;
    }
}

.slideshow-item-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    z-index: 1;
}

.slideshow-items {
    z-index: 1;
}

.slideshow-item-caption {
    display: inline-block;
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 4px 6px;

    background-color: rgba(0, 0, 0, 0.5);
    color: $color-elements-light;

    font-size: 12px;
    font-weight: bold;
    text-decoration: none;

    opacity: 0.8;
    visibility: visible;
    z-index: 3;

    span {
        font-weight: normal;
    }
}

.slideshow-controls {
    position: absolute;
    bottom: 5px;
    left: 5px;
    height: 30px;
    z-index: 4;
}
