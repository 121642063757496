.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    .overlay-container {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;

        background-color: rgba(0, 0, 0, 0.8);
        color: $color-elements-light;

        z-index: 9998;
    }

    .close-button {
        display: block;
        position: absolute;

        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;

        background-color: transparent;
        background-image: url('/img/icons/close-red.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
        color: $color-red;
        font-size: 30px;
        font-weight: bold;
        cursor: pointer;
        z-index: 9999;
    }

    .videoclips-container {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;

        video {
            position: absolute;
            width: 100%;
            cursor: url('/img/icons/arrow-next-trip.png') 0 0, pointer;
        }

        .videoclips-credits {
            position: absolute;
            right: 0;
            bottom: 20px;
            padding: 5px;
            background-color: $color-black;
            color: $color-white;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .zoomgallery-container {
        display: table;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: transparent;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
        vertical-align: middle;

        .zoomgallery-inner {
            display: table-cell;
            vertical-align: middle;
        }

        .zoomgallery-table {
            display: table;
            width: 100%;
        }

        .zoomgallery-closeall {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 0;
        }

        .zoom-background {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 0;
            background-color: transparent;
            appearance: none;
            user-select: none;
        }

        .zoom-button {
            display: table-cell;
            position: relative;
            margin-bottom: 10px;
            margin-left: 10px;

            background-color: transparent;
            cursor: pointer;
            vertical-align: middle;
            z-index: 1;
            user-select: none;

            &.active {
                position: absolute;
                top: 50%;
                left: 50%;
                height: auto;
                cursor: url('/img/icons/arrow-next-trip.png') 0 0, pointer;
                z-index: 2;

                img {
                    transform: scale(1.1);
                    border: 3px solid $color-yellow;
                }
            }

            &.selected {
                img {
                    transform: scale(1.1);
                    border: 3px solid $color-yellow;
                }
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }

            img {
                width: 100px;
                transition: transform 0.16s ease-out;
                border: 3px solid transparent;

                @media #{$mq-medium-screen} {
                    width: 120px;
                }

                @media #{$mq-large-screen} {
                    width: 160px;
                }
            }
        }
    }
}
