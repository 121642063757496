@font-face {
    font-family: 'Berlingske Serif Stbold';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/berlingskeserifstencil-bd-webfont.woff2') format('woff2'),
        url('/fonts/berlingskeserifstencil-bd-webfont.woff') format('woff');
}

@font-face {
    font-family: 'share-regularregular';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/share-regular-webfont.woff2') format('woff2'),
        url('/fonts/share-regular-webfont.woff') format('woff');
}
