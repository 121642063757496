/**
 * Paper animated page
 */

.container-content {
    position: relative;
    width: 100%;
    padding: 0;
}

.paper {
    display: block;
    position: relative;

    .paper-children-container {
        display: block;
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
    }

    .paper-children-mask {
        display: block;
        position: absolute;
        top: -80px;
        left: 0;
        width: 100%;
        height: 100vh;
        background-image: url('/img/backgrounds/background.jpg');
        background-repeat: repeat;
        // border: 1px solid blue;
        z-index: 2;
    }

}
