.reactvoyage,
.reactvoyagesection,
.reactvoyagesegment {
    .nav-menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        margin: 0;
        padding: 0;

        background-color: $color-red;
        color: $color-black;
        font-family: $font-title;

        opacity: 0;
        overflow: hidden;
        visibility: hidden;

        z-index: 9999;

        .inner-table {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        .inner-scene-menu {
            width: 80%;
            margin: 0 auto;
            padding: 20px 0;
            vertical-align: middle;
        }

        .title {
            margin-bottom: 10px;
            text-align: center;

            h2 {
                font-size: 30px;

                a {
                    color: $color-black;
                }
            }
        }

        .inner-controls {
            position: relative;
            width: 100%;
            height: 60px;
            vertical-align: top;

            button {
                position: absolute;
                top: 10px;
                width: 40px;
                height: 40px;
                background-color: transparent;
                cursor: pointer;
            }

            .close {
                right: 10px;
                background-image: url('/img/icons/close-black.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
            }

            .back {
                left: 10px;
                background-image: url('/img/icons/back.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;

                &.reverse {
                    background-image: url('/img/icons/back-reverse.svg');
                }
            }
        }

        h2 {
            display: inline-block;
            position: relative;
            padding: 2px 25px;

            color: $color-text;
            font-family: $font-logo;
            font-size: 60px;
            font-weight: 700;

            z-index: 999;

            a {
                color: $color-red;
                text-decoration: none;
            }

            &.scene {
                background-color: $color-text;
                color: $color-red;
                font-family: $font-logo;
                font-size: 40px;
                font-weight: 400;
                z-index: 9999;
            }
        }

        .scene-menu-link {
            display: block;
            width: 100%;
            background-color: transparent;
            font-family: $font-title;
            line-height: 1;
            appearance: none;

            &.desktop {
                .scene-menu-item {
                    position: absolute;
                    text-align: left;

                    .background {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 22px;
                        visibility: visible;
                    }
                }
            }

            &.mobile {
                // margin-bottom: 4px;
            }

            .scene-menu-item {
                width: 100%;
                text-align: center;
            }

            p {
                position: relative;
                border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            }

            span {
                transition: color 0.15s ease-out, border-color 0.15s ease-out;
                background-color: transparent;
            }

            .foreground {
                display: inline-block;
                // margin: 0;
                // padding: 4px 10px;
                // transition: background-image 0.3s ease-out, background-color 0.3s ease-out;
                // background-color: transparent;
                // background-repeat: repeat;
                // color: $color-text;
                // font-family: $font-logo;
                // font-size: 20px;
                // font-weight: 300;
                // display: inline-block;

                // New Look
                margin: 0;
                padding: 16px 2px;
                transition: background-image 0.3s ease-out, background-color 0.3s ease-out;
                background-color: transparent;
                background-repeat: repeat;
                color: $color-text;
                font-family: 'Roboto', serif;
                font-size: 16px;
                font-weight: 700;

                &.small {
                    // font-size: 12px;
                    // line-height: 16px;
                }
            }

            &:hover {
                span {
                    color: $color-orange;
                }
            }

            &.finished {
                span {
                    color: $color-orange;
                }

                p {
                    border-color: $color-orange;
                }
            }

            &.active {
                span {
                    color: #ffcc33;
                }

                p {
                    border-color: #ffcc33;
                }
            }
        }
    }
}
