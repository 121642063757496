/**
 * News
 */

.news-container {
    width: 100%;
    height: 100%;
    z-index: 2;

    .news-item {
        display: block;
        width: 100%;
        padding-bottom: $padding-default;

        text-decoration: none;

        &:hover {
            .news-item-inner {
                color: $color-orange;
            }
        }

        .news-item-inner {
            display: block;
            width: 100%;
            height: 50px;
            margin: 0;
            padding: $padding-default;

            transition: color 0.6s ease-out;
            background-color: $color-control;

            color: $color-elements-light;
            font-family: $font-home;
            line-height: 1.1;
            box-sizing: border-box;

            .title,
            .subtitle {
                text-decoration: none;
                line-height: 1;
            }

            .title {
                font-size: 18px;
                text-transform: uppercase;
            }

            .subtitle {
                font-size: 14px;
                text-transform: uppercase;
            }

            .background {
                padding-right: $padding-large * 2;
                background-image: url('/img/icons/arrow-strange.svg');
                background-repeat: no-repeat;
                background-position: 100% center; //27px off
            }
        }
    }

    @media #{$mq-medium-screen} {
        min-width: 205px;

        .news-item {
            padding-bottom: $padding-small;
        }
    }
}
