/**
 * Commons
 */
@import 'commons/variables';
@import 'commons/mixins';
@import 'commons/fonts';
@import 'commons/reset';
@import 'commons/general';
@import 'commons/buttons';
@import 'commons/icons';
@import 'commons/inputs';

/**
 * General
 */
body {
    background-color: #937d65;
    background-image: url('/img/backgrounds/mediumBackground.jpg');
    background-repeat: repeat;
    background-position: top left;
    background-size: 100%;

    font-family: $font-text;
    font-size: $font-size-small;
    line-height: 1.35;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

/**
 * Views
 */
@import 'views/lists';
@import 'views/pagination';
@import 'views/filters';
@import 'views/toolbar';
@import 'views/search';
@import 'views/credits';
@import 'views/gallery';
@import 'views/logo';
@import 'views/video';
@import 'views/audio';
@import 'views/slideshow';
@import 'views/scanner';

@import 'views/header';
@import 'views/footer';
@import 'views/content';
@import 'views/sidebar';
@import 'views/splash';
@import 'views/square-grid';
@import 'views/home';
@import 'views/layout';
@import 'views/news';
@import 'views/paper';

/**
 * Trips
 */
@import 'voyages/layout';

@import 'voyages/partials/menu';
@import 'voyages/partials/menuBottom';
@import 'voyages/partials/progress';
@import 'voyages/partials/overlay';
@import 'voyages/partials/play';
@import 'voyages/partials/sceneMenu';
@import 'voyages/partials/navMenu';
