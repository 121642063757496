.sidebar {
    position: relative;
    height: 100%;
    padding: 0;

    transition: margin-top $transition-time-fast ease;
    overflow: hidden;

    .container-col {
        vertical-align: middle;
    }

    &.closed {

        .menu {
            display: none;
        }

    }

    &.collapsed {
        height: 100%;

        .filters {
            padding-top: 20px;
        }
    }

    @media #{$mq-medium-screen} {
        padding: 10px;

        .menu {
            display: block;
            visibility: visible;
        }

        &.closed {

            .menu {
                transform: translate(-100%, 0);
            }

        }

        &.collapsed {
            .scroller {
                top: 48px;
            }
        }

        .filters {
            // padding-top: 170px;
            padding-right: 20px;
            padding-bottom: 40px;
            z-index: 5;
        }

        .scroller {
            position: absolute;
            top: 170px;
            right: -20px;
            bottom: 0;
            left: 0;

            overflow-y: scroll;
        }
    }
}
