.logo {
    position: relative;
    padding: 10px;
    line-height: 1.1;
    z-index: 10;

    h1 {
        max-width: 150px;
        margin: 0;
        text-shadow: 0 0 200px #eee;
    }

    a {
        display: block;
        color: inherit;
        text-decoration: none;

        span {
            transition: text-shadow 0.3s ease-out;
        }
    }

    &.homeGlow {
        &.vertical {
            a {
                &:hover {
                    span {
                        color: $color-white;
                        text-shadow: 0 0 200px #000;
                    }

                    .icon-return-small {
                        background-image: url('/img/icons/return-small-white.svg');
                    }
                }
            }
        }
        &.horizontal {
            a {
                &:hover {
                    span {
                        color: $color-white;
                        text-shadow: 0 0 200px #000;
                    }

                    .icon-return-small {
                        // background-image: url('/img/icons/return-small-white.svg');
                    }
                }
            }
        }
    }

    .title {
        display: block;
        font-family: $font-logo;
        font-size: $font-size-logo;
        font-weight: bold;

        .icon-return-small,
        .show-scrolled {
            display: none;
            visibility: hidden;
        }
    }

    .subtitle {
        display: block;
        font-family: $font-logo;
        font-size: 24px;
        font-weight: bold;
    }

    .show-scrolled {
        display: none;
        visibility: hidden;
    }

    .letter {
        display: inline !important;
        visibility: visible !important;
    }

    @media #{$mq-small-screen} {
        padding: 20px;
    }

    @media #{$mq-medium-screen} {
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 0;

        &.small {
            width: 30px;
            margin-top: 0;

            .title {
                span {
                    display: none;
                    visibility: hidden;
                }
            }

            .subtitle {
                display: none;
                visibility: hidden;
            }
        }

        &.horizontal {
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: 0;
            padding-left: 45px;

            .title {
                span {
                    display: none;
                    visibility: hidden;
                }

                .icon-return-small {
                    display: inline-block;
                    padding: 0 5px 0 10px;
                    visibility: visible;
                }

                .show-scrolled {
                    display: inline;
                    visibility: visible;
                }
            }

            .subtitle {
                display: none;
                visibility: hidden;

                span {
                    &:last-child {
                        display: none;
                        visibility: hidden;
                    }
                }
            }
        }
    }
}
